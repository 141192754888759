import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Messenger",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/messenger.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Messenger`}</h2>
    <p>{`Facebook Messenger messages in Crisp`}</p>
    <hr></hr>
    <p>{`This plugin lets you connect your Facebook Messenger Page to Crisp.`}</p>
    <p>{`You can now handle all your Facebook Messengers customers right from your Crisp Inbox. It's compatible with all the Crisp ecosystem, like the Bot integration, Slack or Zendesk.`}</p>
    <p>{`Benefits of using Crisp with Messenger:`}</p>
    <ul>
      <li parentName="ul">{`Avoid distraction by keeping all your chats in Crisp`}</li>
      <li parentName="ul">{`Be more productive with the Crisp Inbox features (Knowledge Base, Shortcuts, Assignments)`}</li>
      <li parentName="ul">{`Get your user's information (Avatar, Full-name)`}</li>
      <li parentName="ul">{`Integrate it with your team tools`}</li>
      <li parentName="ul">{`Re-target your Messenger users`}</li>
      <li parentName="ul">{`Preserves most messaging features like buttons, typing indicators, file-sharing, gifs and emojis`}</li>
      <li parentName="ul">{`Build a messenger bot in few clicks with the Crisp bot feature`}</li>
      <li parentName="ul">{`Messages you receive are stored in the Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Use the Crisp search engine to search previous conversations`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      